<script>
import { querystring, replace } from 'svelte-spa-router'
import { user } from './stores.js'
let message = {};
let submitted = false;
let confirm_password = null;
import { doJSON, handleError } from './lib.js'

var urlParams = new URLSearchParams($querystring.replace('&amp;', '&'));
var qs = Object.fromEntries(urlParams.entries());

let data = {
    'token': qs.token,
    'email': qs.email
}


function checkConfirm() {
    if(data.password != data.password_confirmation) {
        this.setCustomValidity('The passwords do not match');
        return false;
    }

    this.setCustomValidity('');
    return true;
}

async function resetPassword() {
    message = {};
    console.log(data);
    let [success, response] = await doJSON('/api/reset-password', data);

    if(success) {
        console.log(response);
        message = {
            'text': 'Your password has been reset. <a href="#/">Click here to login</a> with your new password.',
            'class': 'success'
        }
    } else {
        message = await handleError(response, message);
    }
}

</script>
<div class="login">
<form class:submitted="{submitted}" on:submit|preventDefault="{resetPassword}">
    <h1>Reset Your Password</h1>
    <p class="message">Enter {#if !data.token} your code and {/if}your new password below to reset your password.</p>
    {#if message.text}
        <div class="msg {message.class}">{@html message.text}</div>
    {/if}
    {#if !data.token}
    <label>
        <span class="label">RESET CODE</span>
        <input type="text" required bind:value="{data.token}" />
    </label>
    {/if}
    <label>
        <span class="label">PASSWORD</span>
        <input type="password" required bind:value="{data.password}" />
    </label>
    <label>
        <span class="label">CONFIRM PASSWORD</span>
        <input type="password" required on:change="{checkConfirm}" bind:value="{data.password_confirmation}" />
    </label>
    <p><button on:click="{() => { submitted = true}}" class="btn u-w100">RESET PASSWORD</button></p>
    <p class="back"><a href="#/">Back to Login</a></p>
</form>
</div>
<style>
    .back { text-align: center; font-size: 0.8em; }
    .back a { color: var(--brand-accent-color); }
</style>
