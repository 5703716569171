<script>
    import { doJSON, handleError } from "./lib";
    import { doUpload } from "./lib";
    import { push } from "svelte-spa-router";
    import Time from "svelte-time";
    export let params;

    let item = {
        requests: []
    };
    let name = 'nonprofit';
    let ui_name = 'Nonprofit';

    let submitted = false;
    let method = 'PUT';
    let url = '';
    let message = {};

    let files;

    async function init() {
        method = 'PUT';
        url = `/api/${name}`;

        if(params.id == 'new') {
            method = 'POST';
        } else {
            url += '/' + params.id;
            let [success, response] = await doJSON(url, false, 'GET');
            if(success) {
                item = response;
            }
        }
    }

    async function submit() {
        message = {};

        let [success, response] = await doJSON(url, item, method);
        if(success) {

            message = {
                'text': `The ${name} has been updated`,
                'class': 'success'
            }

            item = response;
            init();
        } else {
            await handleError(response, message);
            message = message;
        }
        document.getElementById('main').scrollTo({'top': 0, 'left': 0, 'behavior': 'smooth'});
    }

    async function upload() {
        let resp = await doUpload(files);
        if(resp.ok) {
            let data = await resp.json();
            item.documentation_path = data.filename;
            files = undefined;
        } else {
            message = {
                'text': 'There was an error uploading the file',
                'class': 'error'
            };
        }
    }

    init();
</script>

<h1>Edit { ui_name }</h1>
{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}
<form class:submitted="{submitted}" on:submit|preventDefault="{submit}">
    <label>
        <span class="label">Approved</span>
        <select bind:value="{item.approved}">
            <option value={0}>No</option>
            <option value={1}>Yes</option>
        </select>
    </label>
    <label>
        <span class="label">Name</span>
        <input required bind:value="{item.name}" />
    </label>
    <label>
        <span class="label">FEIN</span>
        <input type="text" required bind:value="{item.fein}" />
    </label>
    <label>
        <span class="label">Proof of Status</span>
        {#if item.documentation_path}
            <p>
                <a target="_blank" href="/uploads/{item.documentation_path}">Click to view: {item.documentation_path}</a>
            </p>  
            <button type="button" class="btn sm-btn" on:click="{() => { delete item.documentation_path; item = item;}}">Remove</button>
        {:else}
        <input accept=".jpg,.jpeg,.pdf,.doc.docx,.png" type="file" bind:files="{ files }" on:change="{() => {upload('status')}}" />
        {/if}
    </label>

    {#if item.requests && item.requests.length > 0}
    <h2>Requests</h2>
    <table class="table click-table">
        <tr>
            <th>Dealer</th>
            <th>Date</th>
            <th>Event Date</th>
            <th>Description</th>
            <th>Amount</th>
            <th>Status</th>
        </tr>
    {#each item.requests as request }
        <tr class="tbody" on:click="{async () => { await push(`/request/${request.id}`) }}">
            <td>{request.dealer.name }</td>
            <td><Time format="YYYY-MM-DD" timestamp={request.createdAt}></Time></td>
            <td>{request.event_date}</td>
            <td>{request.description}</td>
            <td>{request.amount}</td>
            <td>{request.status}</td>
        </tr>
    {/each}
    </table>
    {/if}
        
    <p><button on:click="{() => { submitted = true}}" class="btn u-w100">SUBMIT</button></p>
</form> 