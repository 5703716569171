<script>
import Router, { push, location } from 'svelte-spa-router'
import { user, active_budget } from './stores.js'
import updateCSRF, { fetchWithCSRF, formatMoney } from './lib.js'
import LoginView from './LoginView.svelte'
import ForgotView from './ForgotView.svelte'
import ResetView from './ResetView.svelte'
import DashboardView from './DashboardView.svelte'
import RequestView from './RequestView.svelte'
import SwitchAccountView from './SwitchAccountView.svelte'
import DealersView from './DealersView.svelte'
import DealerView from './DealerView.svelte'
import UsersView from './UsersView.svelte'
import UserView from './UserView.svelte'
import NonprofitsView from './NonprofitsView.svelte'
import NonprofitView from './NonprofitView.svelte'
import VendorsView from './VendorsView.svelte'
import VendorView from './VendorView.svelte'
import ChecksView from './ChecksView.svelte'
import CheckView from './CheckView.svelte'
import AdiView from './AdiView.svelte'
import MenuView from './MenuView.svelte'

$user = {
    'id': false,
    'account_id': false,
    'email': '',
    'is_admin': false,
    'menu': []
};

let mobileMenuOpen = false;

async function checkLogin() {
    await updateCSRF();
    let already_logged_in = await fetch('/api/login')
    if(already_logged_in.ok) {
        $user = await already_logged_in.json();
        if($user.dealers.length == 1) {
            $user.active_dealer = $user.dealers[0];
            $active_budget = $user.active_dealer.budgets.at(-1);
        }
    } else {
        $user.id = false;
    }
}
checkLogin();


const loginRoutes = {
    '/forgot': ForgotView,
    '/reset': ResetView,
    '*': LoginView
}

const switchRoutes = {
    '*': SwitchAccountView
}

const routes = {
    '/': DashboardView,
    '/request/:id': RequestView,
    '/dealer': DealersView,
    '/dealer/:id': DealerView,
    '/user': UsersView,
    '/user/:id': UserView,
    '/nonprofit': NonprofitsView,
    '/nonprofit/:id': NonprofitView,
    '/vendor': VendorsView,
    '/vendor/:id': VendorView,
    '/check': ChecksView,
    '/check/:id': CheckView,
    '/adi': AdiView,
    '/menu': MenuView,
}

async function logout() {
    await fetchWithCSRF('/api/logout', {});
    $user = {
     'id': false,
     'is_admin': false,
    }
    await push('/')
}

</script>

<main class:mobileMenuOpen="{mobileMenuOpen}" class:kctda="{$user.account_id == 2}">
    <nav class="fusion-main-menu" aria-label="Main Menu">
        {#if $user.id !== false}
            <span class="user">
                User: {$user.email}
            </span>
            <button on:click="{logout}" class="logout">Log Out</button>
        {/if}

        <button id="mobile-menu" on:click={() => { mobileMenuOpen = !mobileMenuOpen}}>
            {#if !mobileMenuOpen}☰{:else}✕{/if}
        </button>
    </nav>
{#if $user.id === false}
    <Router routes="{loginRoutes}" restoreScrollState={true} />
{:else if !$user.board_approvals && !$user.agency_approvals && ((!$user.is_admin && $user.active_dealer == null) || ($user.is_admin && $user.active_account == null))}
    <Router routes="{switchRoutes}" restoreScrollState={true} />
{:else}
    <div class="outer">
    <div class="sidebar">
        <div class="logo">
            {#if $user.account_id == 1}
                <img src="/images/catdaa.jpg" alt="" />
            {:else}
                <img src="/images/VIS_toyota_logo_vert_black_RGB_2021.png" alt="" />
                <span style="text-align: center; font-weight: bold; display: block; padding-top: 12px;">Kansas City TDA</span>
            {/if}
        </div>
        <div class="menu-items">
            <ul id="side_menu">
                <li><a href="#/">Dashboard</a></li>
                <li><a href="#/request/new">New Request</a></li>
                {#if $user.is_admin || $user.agency_approvals}
                    <li><a href="#/dealer">Dealers</a></li>
                    {#if $user.is_admin}
                        <li><a href="#/check">Checks</a></li>
                        <li><a href="#/user">Users</a></li>
                    {/if}
                    {#if $user.account_id == 1}
                        <li><a href="#/nonprofit">Nonprofits</a></li>
                        <li><a href="#/adi">ADI Report</a></li>
                    {:else}
                        <li><a href="#/adi">DMA Report</a></li>
                        <li><a href="#/vendor">Vendors</a></li>
                    {/if}
                    {#if $user.is_admin}
                        <li><a href="#/menu">Manage Menu</a></li>
                    {/if}
                {/if}
                {#if  $user.board_approvals}
                    <li><a href="#/adi">DMA Report</a></li>
                {/if}
                {#each $user.menu as item}
                    {#if item.account_id == $user.account_id}
                        <li><a href="{item.url}">{item.label}</a></li>
                    {/if}
                {/each}
            </ul>
            {#if !$user.is_admin && !$user.agency_approvals && !$user.board_approvals &&  $user.active_dealer && $user.active_dealer.budgets.length > 0 && $active_budget}
            <ul id="budget_menu">
                <li class="first">
                    <span>Budget Year</span>
                    <select bind:value={$active_budget} id="budget_year">
                        {#each $user.active_dealer.budgets as budget}
                            <option value="{budget}">{budget.year}</option>
                        {/each}
                    </select>
                </li>
                {#if $user.account_id == 1}
                <li class="alignright">Year Budget Total: <span>{ formatMoney($active_budget.amount) }</span></li>
                <li class="alignright">Expended Funds: <span>{ formatMoney($active_budget.expended) }</span></li>
                <li class="alignright">Pending Settlement: <span>{ formatMoney($active_budget.pending) }</span></li>
                {:else}
                <li class="alignright">Forecasted Budget: <span>{ formatMoney($active_budget.amount) }</span></li>
                <li class="alignright">YTD Spend: <span>{ formatMoney($active_budget.expended) }</span></li>
                <li class="alignright">Pending Requests: <span>{ formatMoney($active_budget.pending) }</span></li>
                {/if}
                <li class="last alignright"><strong>Available Balance:</strong> <span>{ formatMoney($active_budget.available) }</span></li>
            </ul>
            {/if}
            {#if $user.dealers && $user.dealers.length > 1}
            <div class="switch-dealer">
                Dealer: {$user.active_dealer.name}
                <button on:click="{() => { $user.active_dealer = null; }}">Switch</button>
            </div>
            {/if}
            {#if $user.is_admin}
            <div class="switch-dealer">
                Account: {$user.active_account.name}
                <button on:click="{() => { $user.active_account = null; }}">Switch</button>
            </div>
            {/if}
        </div>
    </div>
    <div class="inner" id="main">
        <Router {routes} restoreScrollState={true} on:routeLoading={() => { mobileMenuOpen = false; }} />
    </div>
    </div>
{/if}

</main>

<style>
    main {
        display: flex;
        flex-direction: column;
    }

    .user {
        color: #0f0;
    }

    .logout {
        background: #fff;
        border: 0;
        margin-left: 2ch;
        padding: 0.5rem 1.0rem;
        font-size: 13.333px;
        font-weight: 800;
        cursor: pointer;
        color: #000;
        text-decoration: none;
    }

</style>
