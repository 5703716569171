<script>
import { user } from './stores.js'
let message = {};
let submitted = false;
import { doJSON, handleError } from './lib.js'


async function resetPassword() {
    message = {};
    let data = { ...$user };
    let [success, response] = await doJSON('/api/forgot-password', data);

    if(success) {
        message = {
            'text': response.status,
            'class': 'success'
        };
    } else {
        message = await handleError(response, message);
    }
}

</script>
<div class="login">
    <form class:submitted="{submitted}" on:submit|preventDefault="{resetPassword}">
        <h1>Reset Your Password</h1>
        <p class="message">Enter your email address below and we will email you instructions to reset your password.</p>
        {#if message.text}
            <div class="msg {message.class}">{message.text}</div>
        {/if}
        <label>
            <span class="label">EMAIL ADDRESS</span>
            <input type="email" required bind:value="{$user.email}" placeholder="example@example.com" />
        </label>
        <p><button on:click="{() => { submitted = true}}" class="btn u-w100">SEND RESET</button></p>
        <p class="back"><a href="#/">Back to Login</a></p>
    </form>
</div>
<style>
    .back { text-align: center; font-size: 0.8em; }
    .back a { color: var(--brand-accent-color); }
</style>
