import Cookies from 'js-cookie'

export async function updateCSRF() {
    fetch('/api/sanctum/csrf-cookie').then(async resp => {
        console.log('Fetched XSRF');
    });
}

export async function refreshBudget($active_budget) {
    let [success, response] = await doJSON("/api/budget/" + $active_budget.id, false, "GET");
    if (success) {
        $active_budget.expended = response.expended;
        $active_budget.pending = response.pending;
        $active_budget.available = response.available;
    }
}


export function getAPIHeaders() {
    return {
        'Accept': 'application/vnd.api+json',
        'Content-Type': 'application/vnd.api+json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-XSRF-TOKEN': Cookies.get('XSRF-TOKEN')
    }
}

export async function fetchWithCSRF(url, data, method='POST') {
    let payload = {
        'method': method,
        'headers': getAPIHeaders()
    }
    if(data) {
        payload.body = JSON.stringify(data);
    }
    return fetch(url, payload)
}

export async function doJSON(url, data, method='POST', retry=true) {
    let response = await fetchWithCSRF(url, data, method);
    if(response.ok) {
        data = await response.json();
        return [true, data];
    }

    // CSRF token expired, refresh and retry
    if(response.status == 419 && retry) {
        await updateCSRF();
        return doJSON(url, data, method, false);
    }

    // 429 Too Many Requests - Rate Limiting
    if(response.status == 429 && method == 'GET' && retry !== false ) {
        if(retry === true) {
            retry = 0;
        }

        retry += 5000;

        if(retry > 30000) {
            return [false, response];
        }

        await new Promise(resolve => setTimeout(resolve, retry));
        return doJSON(url, data, method, retry);
    }

    return [false, response];
}

export async function doUpload(file_store, retry=true) {

    const formData = new FormData();
    formData.append('file', file_store[0]);

    let headers = getAPIHeaders();
    delete headers['Content-Type'];

    return fetch('/api/upload', {
        method: 'POST',
        headers: headers,
        body: formData
    });
}

export async function handleError(response, message) {
    try {
        let data = await response.json();
        message = {
            'class': 'error',
            'text': data.message || data.error || data.errors[0].detail
        };
    } catch(e) {
        message.class = 'error';
        message.text = 'An unknown error occurred. Please contact support.';
    }

    return message;

}

export function formatDate(timestr) {
    return new Date(timestr).toLocaleString(undefined).replace(',', ' ');
}

export function formatShortDate(datetimeStr) {
    return new Date(datetimeStr).toLocaleString(undefined, {weekday: 'long', hour: 'numeric', minute: '2-digit'})
}

export function capitalize(str) {
    return str[0].toUpperCase() + str.slice(1)
}

export function getUrlParams() {
    return new URL(location.href).searchParams;
}

export function formatMoney(number) {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });
      
    return formatter.format(number);
}

export function jsonCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
}

export default updateCSRF

export async function downloadBlob(content, contentType, fileName) {
    var blob = new Blob([ content ], { type : contentType });
    if(navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
    } else {
        let url = (window.URL || window.webkitURL).createObjectURL( blob );
        let save_link = document.createElementNS("http://www.w3.org/1999/xhtml", "a");
        save_link.href = url;
        save_link.download = fileName;
        var event = document.createEvent("MouseEvents");
        event.initMouseEvent(
                "click", true, false, window, 0, 0, 0, 0, 0,
                false, false, false, false, 0, null
                ); 
        save_link.dispatchEvent(event);
    }
}
