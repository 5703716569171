<script>
import { active_budget, user } from './stores.js'
import { doJSON } from './lib.js'

let password = '';
let submitted = false;
let message = {};

async function submit() {
    let data = { ...$user };
    data.password = password;

    let [success, response] = await doJSON('/api/login', data);
    if(success) {
        $user = response;
        if($user.dealers.length == 1) {
            $user.active_dealer = $user.dealers[0];
            $active_budget = $user.active_dealer.budgets.at(-1);
        } else {
            $user.active_dealer = null
            $active_budget = {
                'year': new Date().getFullYear()
            };
        }
    } else {
        let data = await response.json();
        message = {
            'class': 'error',
            'text': data.message || 'There was an error communicating with the server'
        }
    }
}

</script>
<div class="login">
<h1>Sign In to Continue</h1>
{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}

<form autocomplete="off" class:submitted="{submitted}" on:submit|preventDefault="{submit}">
    <label>
        <span class="label">EMAIL ADDRESS</span>
        <input
        type="email" name="email" required bind:value="{$user.email}" placeholder="example@example.com" />
    </label>
    <label>
        <span class="label">PASSWORD</span>
        <input name="password" type="password" required bind:value="{password}" />
    </label>
    <p><button on:click="{() => { submitted = true}}" class="btn u-w100">SIGN IN</button></p>
    <p class="forgot"><a href="#/forgot">Forgot Password</a></p>
</form>
</div>
<style>
    .forgot { text-align: center; font-size: 0.8em; }
    .forgot a { color: var(--brand-accent-color); }
</style>
