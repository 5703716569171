<script>
    import { doJSON, handleError, getAPIHeaders } from "./lib";
    import user from "./stores";

let items = [];
let submitted = false;
let message = {};
let files = [];


async function init() {
        let [success, response] = await doJSON(`/api/menu?account_id=${$user.account_id}`, false, 'GET');
        if(success) {
            // Filter out items in response if account_id doesn't match $user.account_id
            items = response.filter(item => item.account_id == $user.account_id);
        }
}

async function submit() {
    message = {};

    let [success, response] = await doJSON(`/api/menu?account_id=${$user.account_id}`, items, 'PUT');
    if(success) {

        message = {
            'text': `The menu has been updated`,
            'class': 'success'
        }

        items = response;
        $user.menu = items;
    } else {
        await handleError(response, message);
        message = message;
    }
    document.getElementById('main').scrollTo({'top': 0, 'left': 0, 'behavior': 'smooth'});
}

async function addItem() {
    items.push({'account_id': $user.account_id});
    items = items;
}

function move(item, fromIndex, toIndex) {
    items.splice(fromIndex, 1);
    items.splice(toIndex, 0, item);
    items = items;
}

function upload(idx) {

    const formData = new FormData();
    formData.append('file', files[idx][0]);

    let headers = getAPIHeaders();
    delete headers['Content-Type'];

    fetch('/api/upload', {
        method: 'POST',
        headers: headers,
        body: formData
    }).then(async (response) => {
        let data = await response.json()
        items[idx].url = '/uploads/' +  data['filename'];
    }).catch((error) => {
        console.error('Error:', error);
    });
}



init();

</script>

<h1>
    Menu
    <span style="float: right"><button on:click="{addItem}" class="btn">New Menu Item</button></span>
</h1>

{#if message.text}
    <div class="msg {message.class}">{message.text}</div>
{/if}

<form autocomplete="off" class:submitted="{submitted}" on:submit|preventDefault="{submit}">
<table class="table menu">
    <tr>
        <th>Label</th>
        <th>URL</th>
        <th>Move</th>
        <th>Delete</th>
    </tr>
    {#if items.length == 0 }
    <tr>
        <td style="text-align: center" colspan="4">No menu items found</td>
    </tr>
    {/if}
    {#each items as item, idx }
    {#if item.account_id == $user.account_id}
    <tr>
        <td><input required bind:value="{item.label}" /></td>
        <td>
            <label>
                <span>Enter a URL or Upload a File</span>
                <input class="url" required bind:value="{item.url}" />
                <input accept=".jpg,.jpeg,.pdf,.doc,.docx,.png" type="file" bind:files="{ files[idx] }" on:change="{() => { upload(idx)}}" />
            </label>
        </td>
        <td>
            {#if idx > 0}<button type="button" class="btn" on:click="{move(item, idx, idx-1)}">⬆</button>{/if}
            {#if idx < items.length-1}<button type="button" class="btn" on:click="{move(item, idx, idx+1)}">⬇</button>{/if}
        </td>
        <td>
            <button  type="button" class="btn" on:click="{() => { items.splice(idx, 1); items = items;}}">⌫ </button>
        </td>
    </tr>
    {/if}
    {/each}
</table>    
    <p><button on:click="{() => { submitted = true}}" class="btn u-w100">SAVE</button></p>
</form>

<style>
    .url {
        margin-bottom: 12px;
    }
</style>