<script>
    import { doJSON } from "./lib";
    import { push } from "svelte-spa-router";
    import user from "./stores";

    let items = [];
    let name = 'user';
    let ui_name = 'User';

    function getItems() {
        let params = {
            'account_id': $user.account_id,
        }
        var query_string = new URLSearchParams(params).toString();
        doJSON(`/api/${name}?` + query_string, false, 'GET').then(([success, response]) => {
            if(success) {
                items = response.data;
            }
        });
    }

    getItems();
</script>

<h1>Users 
    <span style="float: right"><a class="btn" href="#/{name}/new">New {ui_name}</a></span>
</h1>

<div style="text-align: right">
</div>

<table class="table user click-table">
    <tr>
        <th>Name</th>
        <th>Admin</th>
        <th>Active</th>
        <th>Email</th>
        <th>Dealers</th>
    </tr>
    {#if items.length == 0 }
    <tr>
        <td style="text-align: center" colspan="6">No { ui_name }s found</td>
    </tr>
    {/if}
    {#each items as item }
        <tr class="tbody" on:click="{async () => { await push(`/${name}/${item.id}`) }}">
            <td>{item.name}</td>
            <td>{item.is_admin ? "Yes" : "No"}</td>
            <td>{item.active ? "Yes" : "No"}</td>
            <td>{item.email}</td>
            <td>
                {#each item.dealers as dealer }
                    {dealer.number} - {dealer.name}<br />
                {/each}
            </td>
        </tr>
    {/each}
</table>    