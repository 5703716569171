<script>
    import { sortParams } from "./stores";
    import { doJSON } from "./lib";
    import { push } from "svelte-spa-router";
    import SortHeader from "./SortHeader.svelte";

    let items = [];
    let name = 'nonprofit';
    let ui_name = 'Nonprofit';
    let filterStatus = '';
    let filterName = '';
    let filterFEIN = '';

    function getItems() {
        console.log(filterName);
        let params = {
            'sort': $sortParams.sortKey,
            'sortDir': $sortParams.sortDir,
        }
        if(filterStatus !== '') {
            params['approved'] = filterStatus;
        }

        if(filterName) {
            params['name'] = filterName;
        }

        if(filterFEIN) {
            params['fein'] = filterFEIN;
        }


        var query_string = new URLSearchParams(params).toString();
        doJSON(`/api/${name}?` + query_string, false, 'GET').then(([success, response]) => {
            if(success) {
                items = response.data;
            }
        });
    }

    $sortParams = {
        sortKey: 'approved',
        sortDir: 'desc',
        updateFunction: getItems,
    }

    getItems();
</script>

<h1>Nonprofits 
    <span style="float: right"><a class="btn" href="#/{name}/new">New {ui_name}</a></span>
</h1>

    <div class="filters">
        <div class="flex">
            <div>
            <label for="filter-by-status">
                <span class="label">Filter by Status</span>
            </label>
            <div class="select-wrap">
                <select id="filter-by-status" bind:value={filterStatus} on:change="{getItems}">
                    <option value="">ALL</option>
                    <option value="1">Approved</option>
                    <option value="0">Unapproved</option>
                    <option>Pending Review</option>
                </select>
            </div>
        </div>
            <label>
                <span class="label">Name</span>
                <input bind:value={filterName} on:input="{getItems}" />
            </label>
            <label>
                <span class="label">FEIN</span>
                <input bind:value={filterFEIN} on:input="{getItems}" />
            </label>
        </div>
    </div> 


<table class="table user click-table">
    <tr>
        <SortHeader column="name">Name</SortHeader>
        <SortHeader column="approved">Approved</SortHeader>
        <SortHeader column="fein">FEIN</SortHeader>
        <th>Notes</th>
    </tr>
    {#if items.length == 0 }
    <tr>
        <td style="text-align: center" colspan="6">No { ui_name}s found</td>
    </tr>
    {/if}
    {#each items as item }
        <tr class="tbody" on:click="{async () => { await push(`/${name}/${item.id}`) }}">
            <td>{item.name}</td>
            <td>
                {#if item.created_at == item.updated_at}
                    (Pending Review)
                {:else}
                    {item.approved? 'Yes' : 'No'}
                {/if}
            </td>
            <td>{item.fein}</td>
            <td>{item.notes || ''}</td>
        </tr>
    {/each}
</table>    

<style>
    .filters label {
     display: block;
     margin: 0;
     margin-right: 1em;
    }
</style>