<script>
    import { doJSON } from "./lib";
    import { push } from "svelte-spa-router";
    import user from "./stores";

    let items = [];

    if(!$user.is_admin && !$user.agency_approvals) {
        console.log($user);
        push('/');
    }

    function getDealers() {
        let params = {
            'account_id': $user.account_id,
        }
        var query_string = new URLSearchParams(params).toString();
        doJSON('/api/dealer?' + query_string, false, 'GET').then(([success, response]) => {
            if(success) {
                items = response.data;
            }
        });
    }

    getDealers();
</script>

<h1>Dealers 
    <span style="float: right"><a class="btn" href="#/dealer/new">New Dealer</a></span>
</h1>

<div style="text-align: right">
</div>

<table class="table dealer click-table">
    <tr>
        <th>Number</th>
        <th>Name</th>
    </tr>
    {#if items.length == 0 }
    <tr>
        <td style="text-align: center" colspan="6">No dealers found</td>
    </tr>
    {/if}
    {#each items as item }
        <tr class="tbody" on:click="{async () => { await push(`/dealer/${item.id}`) }}">
            <td>{item.number}</td>
            <td>{item.name}</td>
        </tr>
    {/each}
</table>    