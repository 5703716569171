<script>
    import { doJSON } from './lib.js';
    import { user, active_budget } from './stores.js'
    import { querystring } from 'svelte-spa-router';

let submitted = false;
let message = {};
let chosen_dealer = {};
let accounts = [];
let chosen_account = {};
let params = new URLSearchParams($querystring);

if($user.is_admin) {
    doJSON('/api/account', false, 'GET').then(([success, response]) => {
        if(success) {
            if(params.has('account_id')) {
                var account_id = params.get('account_id');
                chosen_account = response.find(account => account.id == account_id);
                if(chosen_account) {
                    submit();
                }
                return;
            }
            accounts = response;
        }
    });
}

async function submit() {
    if($user.is_admin) {
        $user.active_account = chosen_account;
        $user.account_id = chosen_account.id;
    } else {
        $user.active_dealer = chosen_dealer;
        $active_budget = $user.active_dealer.budgets.at(-1);
    }
}


</script>
<div class="login">
    {#if $user.is_admin}
        <h1>Choose a Account</h1>
        {#if message.text}
            <div class="msg {message.class}">{message.text}</div>
        {/if}

        <form class:submitted="{submitted}" on:submit|preventDefault="{submit}">
            {#each accounts as account}
            <label class="radio">
                <input name="account_id" type="radio" required bind:group="{chosen_account}" value="{account}" />
                <span class="label">{account.name}</span>
            </label>
            {/each}
            <p class="submit-btn"><button on:click="{() => { submitted = true}}" class="btn u-w100">SWITCH</button></p>
        </form>
    {:else}
        <h1>Choose a Dealer Account</h1>
        {#if message.text}
            <div class="msg {message.class}">{message.text}</div>
        {/if}

        <form class:submitted="{submitted}" on:submit|preventDefault="{submit}">
            {#each $user.dealers as dealer}
            <label class="radio">
                <input name="active_dealer" type="radio" required bind:group="{chosen_dealer}" value="{dealer}" />
                <span class="label">{dealer.name}</span>
            </label>
            {/each}
            <p class="submit-btn"><button on:click="{() => { submitted = true}}" class="btn u-w100">SWITCH</button></p>
        </form>
    {/if}
</div>
<style>
    .login {
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .login form {
        background: #eee;
        box-shadow: 0 0 3px rgba(0,0,0,0.3);
        border-radius: 5px;
        max-width: 500px;
        box-sizing: border-box;
        padding: 24px 5%;
    }
    .forgot { text-align: center; font-size: 0.8em; }
    .forgot a { color: var(--brand-accent-color); }
</style>
