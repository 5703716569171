import './app.css'
import App from './App.svelte'

import * as Sentry from "@sentry/svelte";

Sentry.init({
  dsn: "https://decf796f71ef39e04283d9f9ff1aadf5@o4505885471604736.ingest.sentry.io/4505885500309504"
});

const app = new App({
  target: document.getElementById('app'),
})

export default app
