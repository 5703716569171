<script>
    import { sortParams } from "./stores";
    export let column = '';

    function sortBy() {
        console.log($sortParams);
        if($sortParams.sortKey == column) {
            $sortParams.sortDir = $sortParams.sortDir == 'asc' ? 'desc' : 'asc';
        }
        $sortParams.sortKey = column;
        $sortParams.updateFunction();
    }
</script>

    <th 
        class:asc={$sortParams.sortKey==column && $sortParams.sortDir == 'asc'}
        class:desc={$sortParams.sortKey==column && $sortParams.sortDir == 'desc'}
        class="sortable"
        on:click={sortBy}>
            <slot />
    </th>